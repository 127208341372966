<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ASSETS_IMAGE_LOGO, DEFAULT_AVATAR } from '/@/utils/common/assets-images.ts'
import {
  CaretDownSmallIcon,
  CaretUpSmallIcon,
  CompassIcon,
  GridViewIcon,
  HomeIcon,
  LinkIcon,
  SearchIcon,
  StarIcon,
  CloseIcon,
  ThumbUp1Icon,
} from 'tdesign-icons-vue-next'
import { useResourcePackageDisplayModeStore } from '/@/store/modules/resource/resource-package-display-mode.ts'
import * as qs from 'qs'
import { isXwgBrowser } from '/@/utils/common/device.ts'
import { openXwgBrowserFtWechatMiniProgram, redirectToOpenFtWechatMiniProgram } from '/@/utils/wechat'
import { useResourceLibraryStore } from '/@/store/modules/resource/resource-library.ts'
import { useUserStore } from '/@/store/modules/user.ts'
import { useLibraryUserStore } from '/@/store/modules/library-user.ts'

type NavigateRouteMenuOption = {
  label: string
  callback: () => void
}

export default defineComponent({
  name: 'ResourceSquareLayout',
  components: { CaretUpSmallIcon, CaretDownSmallIcon, SearchIcon, HomeIcon, CompassIcon, StarIcon, GridViewIcon, CloseIcon, ThumbUp1Icon },
  data() {
    const router = useRouter()
    const route = useRoute()
    const userStore = route.name?.toString().includes('ResourceSquare') ? useUserStore() : useLibraryUserStore()
    const resourcePackageDisplayModeStore = useResourcePackageDisplayModeStore()
    return {
      SearchIcon,
      GridViewIcon,
      ASSETS_IMAGE_LOGO,
      resourcePackageDisplayModeStore,
      router,
      route,
      userStore,
      DEFAULT_AVATAR,
      avatar_error: false,
      popup_visible: {
        search: false,
        menu: false,
      },
      form: {
        keyword: '',
      },
      current_square_name: {
        ResourceSquareRecommendIndex: '推荐',
        ResourceSquareLatestIndex: '最新',
        ResourceSquareCollectIndex: '收藏',
        ResourceSquareSearchIndex: '搜索',
        ResourceLibraryIndexIndex: '热门',
        ResourceLibraryRecommendIndex: '推荐',
        ResourceLibraryLatestIndex: '最新',
        ResourceLibraryCollectIndex: '收藏',
        ResourceLibrarySearchIndex: '搜索',
      } as Record<string, string>,
      route_menu_drop_down_value: '',
      resource_library_id: route.params?.resource_library_id ? route.params.resource_library_id?.toString() : '',
      resource_library: undefined as any,
    }
  },
  computed: {
    avatar_url() {
      if (this.userStore.avatar && !this.avatar_error) {
        return this.userStore.avatar
      }
      return this.DEFAULT_AVATAR
    },
    current_route_name() {
      const route = this.route
      return typeof route.name === 'string' ? route.name : ''
    },
    show_top_right_menu() {
      const route = this.route
      if (this.current_square_name[this.current_route_name]) return true
      return route.query.ctrm !== null
    },
    index_route() {
      return { name: 'ResourceLibraryIndexIndex', query: { tag: this.route?.query?.tag } }
    },
    recommend_route() {
      return { name: 'ResourceLibraryRecommendIndex', query: { tag: this.route?.query?.tag } }
    },
    latest_route() {
      return { name: 'ResourceLibraryLatestIndex', query: { tag: this.route?.query?.tag } }
    },
    collect_route() {
      return { name: 'ResourceLibraryCollectIndex' }
    },
    route_menu_drop_down_options(): NavigateRouteMenuOption[] {
      const items: NavigateRouteMenuOption[] = []
      const that = this

      items.push({ label: '首页', callback: () => that.router.push(that.index_route) })
      items.push({ label: '推荐', callback: () => that.router.push(that.recommend_route) })
      items.push({ label: '最新', callback: () => that.router.push(that.latest_route) })
      items.push({ label: '收藏', callback: () => that.router.push(that.collect_route) })

      return items
    },
  },
  async mounted() {
    await this.userStore.get_info()
    this.fetchResourceLibrary().then()
  },
  methods: {
    async fetchResourceLibrary() {
      if (!this.resource_library_id) return
      this.resource_library = await useResourceLibraryStore().get_resource_library(this.resource_library_id)
    },
    async onFormSubmit() {
      this.popup_visible.search = false
      if (!this.form.keyword) {
        window.open(
          this.router.resolve({
            name: 'ResourceLibraryIndexIndex',
            params: {
              resource_library_id: this.resource_library_id,
            },
          }).href,
          '_self'
        )
        return
      }
      window.open(
        this.router.resolve({
          name: 'ResourceLibrarySearchIndex',
          query: {
            keyword: this.form.keyword,
            tag: undefined,
          },
        }).href,
        '_self'
      )
    },
    async onFormReset() {
      this.form.keyword = ''
      this.popup_visible.search = false
      window.open(
        this.router.resolve({
          name: 'ResourceLibraryIndexIndex',
          params: {
            resource_library_id: this.resource_library_id,
          },
        }).href,
        '_self'
      )
    },
    get_popup_attach_element() {
      return document.getElementById('resource-square-layout-container') || document.body
    },
    onChangeRouteMenuDropdown() {
      for (let option of this.route_menu_drop_down_options) {
        if (option.label === this.route_menu_drop_down_value) {
          option.callback()
          return
        }
      }
    },
    hide_popup_visible_menu() {
      this.popup_visible.menu = false
    },
    get_more_action_items() {
      const that = this
      const items: any[] = [...this.resourcePackageDisplayModeStore.getChangeActionSheets()]

      const link_list: Array<{
        name: string
        page: string
        query?: any
      }> = [{ name: '流量管理', page: '/pages/user/traffic/index/index' }]
      for (const link of link_list) {
        items.push({
          icon: LinkIcon,
          label: link.name,
          callback() {
            let query = { name: encodeURIComponent(link.name) }
            if (typeof link.query === 'object') {
              query = Object.assign(query, link.query)
            }
            const query_string = qs.stringify(query)
            if (isXwgBrowser()) {
              openXwgBrowserFtWechatMiniProgram(link.page, query_string)
            } else {
              redirectToOpenFtWechatMiniProgram(link.page, query_string)
            }
          },
        })
      }

      const short_url_map: Record<string, string> = {
        // s4ok: '如何在这里发布您的资源包',
        // w4g4: 'CC空间介绍',
        // '040k': '关于资源包',
      }

      Object.keys(short_url_map).forEach((key) => {
        items.push({ icon: LinkIcon, label: short_url_map[key], callback: () => window.open(`https://i.xwg.cc/s/${key}`, '_blank') })
      })

      for (let item of items) {
        const callback = item.callback
        item.callback = () => {
          callback && callback()
          that.popup_visible.menu = false
        }
      }

      return items
    },
  },
})
</script>

<template>
  <div class="resource-square-layout-container-root">
    <div class="resource-square-layout-container-wrapper">
      <div class="resource-square-layout-container" id="resource-square-layout-container" :style="route.meta.style">
        <div class="resource-square-layout-container-header">
          <div class="resource-square-layout-container-header-left">
            <router-link
              class="resource-square-layout-logo-link"
              :to="{
                name: 'ResourceLibraryIndexIndex',
                params: { resource_library_id },
                query: { tag: resource_library?.tag_list?.[0]?.tag_id },
              }"
              replace
            >
              <t-image class="resource-square-layout-logo" :src="ASSETS_IMAGE_LOGO" />
              <span v-if="resource_library">{{ resource_library.name }}</span>
              <span v-else>资源库</span>
            </router-link>
          </div>
          <div class="resource-square-layout-container-header-right">
            <div class="resource-square-layout-item resource-square-layout-item-search cursor-pointer" @click="popup_visible.search = true">
              <search-icon />
            </div>
            <div class="resource-square-layout-item resource-square-layout-item-avatar cursor-pointer">
              <router-link :to="{ name: 'ResourceLibrarySearchIndex', query: { suid: userStore.suid } }">
                <t-avatar :image="avatar_url" size="24px" @error="() => (avatar_error = true)" />
              </router-link>
            </div>

            <div class="resource-square-layout-item cursor-pointer" v-if="current_square_name[current_route_name]">
              <t-popover v-model="popup_visible.menu" close-on-click-outside placement="bottom" theme="light" :show-arrow="false">
                <template #content>
                  <ul>
                    <li>
                      <router-link :to="index_route" @click="hide_popup_visible_menu">
                        <home-icon />
                        <span>热门</span>
                      </router-link>
                    </li>
                    <li v-if="0">
                      <router-link :to="recommend_route" @click="hide_popup_visible_menu">
                        <thumb-up1-icon />
                        <span>推荐</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="latest_route" @click="hide_popup_visible_menu">
                        <compass-icon />
                        <span>最新</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="collect_route" @click="hide_popup_visible_menu">
                        <star-icon />
                        <span>收藏</span>
                      </router-link>
                    </li>
                    <li v-for="(item, index) in get_more_action_items()" :key="index" @click="item.callback">
                      <component :is="item.icon ? item.icon : GridViewIcon"></component>
                      <span>{{ item.label }}</span>
                    </li>
                  </ul>
                </template>
                <template #default>
                  <div class="rsl-menu-button">
                    <span>{{ current_square_name[current_route_name] || '更多' }}</span>
                    <caret-up-small-icon v-if="popup_visible.menu" />
                    <caret-down-small-icon v-else />
                  </div>
                </template>
              </t-popover>
            </div>
          </div>
        </div>
        <div class="resource-square-layout-container-main">
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </div>
        <div class="resource-square-layout-container-footer">
          <t-popup
            v-model="popup_visible.search"
            class="resource-square-layout-container-search-popup"
            :attach="get_popup_attach_element"
            destroy-on-close
            placement="top"
          >
            <t-form label-align="top" :data="form" @submit="onFormSubmit" @reset="onFormReset">
              <t-form-item label="关键字：" name="keyword">
                <t-input v-model="form.keyword" borderless placeholder="可搜索资源包名称、发布者用户名等..." />
              </t-form-item>
            </t-form>
            <div style="padding-left: 16px; padding-right: 16px; margin-bottom: 20px">
              <t-row :gutter="10">
                <t-col :span="12">
                  <t-button block theme="light" @click="onFormReset">
                    <close-icon />
                    清空
                  </t-button>
                </t-col>
                <t-col :span="12">
                  <t-button block theme="primary" @click="onFormSubmit">
                    <search-icon />
                    搜索
                  </t-button>
                </t-col>
              </t-row>
            </div>
          </t-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .resource-square-layout-container-root {
    width: 100vw;
    height: 100vh;
    background: none !important;

    .resource-square-layout-container-wrapper {
      .resource-square-layout-container {
        --resource-square-layout-container-outer-padding: 0;
        background-color: white !important;
        width: 100vw !important;
        height: 100vh !important;
        position: absolute !important;
        margin: 0 !important;
        border-radius: 0 !important;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.resource-square-layout-container-root {
  width: 100vw;
  height: var(--100vh, 100vh);
  background: url('/images/bg.jpg') no-repeat center center / cover;
  position: absolute;

  .resource-square-layout-container-wrapper {
    background-color: #ffffff4d;
    width: 100%;
    height: 100%;
    position: absolute;

    .resource-square-layout-container {
      --resource-square-layout-container-outer-padding: 20px;
      max-width: 500px;
      overflow: hidden;
      background-color: white;
      margin: var(--resource-square-layout-container-outer-padding) auto;
      height: calc(100vh - (var(--resource-square-layout-container-outer-padding) * 2));
      border-radius: var(--td-swiper-border-radius, 10px);
      --resource-square-layout-container-top-padding: 10px;
      --resource-square-layout-container-bottom-padding: 5px;
      --resource-square-layout-container-horizontal-padding: 15px;
      --resource-square-layout-container-header-height: calc(
        24px + var(--resource-square-layout-container-top-padding) + var(--resource-square-layout-container-bottom-padding)
      );
      --resource-square-layout-container-main-height: calc(
        100% - var(--resource-square-layout-container-header-height, 0) - var(--resource-square-layout-container-outer-padding, 0)
      );
      --resource-square-layout-container-main-horizontal-padding: var(--resource-square-layout-container-horizontal-padding);

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: var(--resource-square-layout-container-top-padding);
        margin-bottom: var(--resource-square-layout-container-bottom-padding);
        padding-left: var(--resource-square-layout-container-horizontal-padding);
        padding-right: var(--resource-square-layout-container-horizontal-padding);
        height: var(--resource-square-layout-container-header-height);

        @media screen and (max-width: 300px) {
          &-left {
            display: none !important;
          }
        }

        &-left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          .resource-square-layout-logo-link {
            display: flex;
            align-content: center;
            justify-content: flex-start;
            font-size: 20px;
            color: var(--primary-color);
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .resource-square-layout-logo {
              height: 24px;
              width: 24px;
              object-fit: contain;
              margin-right: 5px;
              border-radius: 5px;
            }
          }
        }

        &-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin-left: auto;

          .resource-square-layout-item {
            &:not(:first-child) {
              margin-left: 20px;
            }

            .rsl-menu-button {
              font-size: var(--td-font-size-m);
              color: var(--primary-text-color);
              display: flex;
              align-items: center;

              .t-icon {
                margin-left: 0;
                color: var(--secondary-text-color);
              }
            }

            .t-icon {
              width: 20px;
              height: 20px;
              color: var(--info-text-color);
              cursor: pointer;
            }

            .t-popover {
              ul {
                list-style: none;
                padding-inline-start: 10px;
                margin: 0;

                li {
                  line-height: 2.5em;
                  color: var(--primary-text-color);
                  display: flex;
                  align-items: center;
                  width: 100%;

                  &:not(:last-child) {
                  }

                  .t-icon {
                    color: var(--secondary-text-color);
                    margin-right: 5px;
                    width: 1em;
                    height: 1em;
                  }

                  a {
                    color: var(--primary-text-color);
                    display: flex;
                    align-items: center;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      &-main {
        padding-left: var(
          --resource-square-layout-container-main-horizontal-padding,
          var(--resource-square-layout-container-horizontal-padding),
          0
        );
        padding-right: var(
          --resource-square-layout-container-main-horizontal-padding,
          var(--resource-square-layout-container-horizontal-padding),
          0
        );
        height: var(--resource-square-layout-container-main-height);
        overflow: auto;
      }

      :deep() {
        .t-popup {
          .t-form {
            margin-bottom: 20px;
          }

          .t-button.t-button--block {
            .t-button__content {
              display: flex;
              align-items: center;
              .t-icon {
                margin-right: 5px;
                font-size: 1.2em;
              }
            }
          }
        }
      }
    }
  }
}
</style>
