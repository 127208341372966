import { defineStore } from 'pinia'
import { getResourceLibraryInfo } from '../../../api/api24/resource/resource-library.ts'
import { navigate_to_error } from '../../../utils/common'

export type ResourceLibraryTag = {
  tag_id: string
  name: string
  index: number
  fixed: boolean
  hidden: boolean
}

export type ResourceLibrary = {
  resource_library_id: string
  id: string
  name: string
  thumb_url: string
  create_at: string
  modify_at: string
  package_count: number
  tag_count: number
  tag_list: ResourceLibraryTag[]
  web_url: string
}

export const useResourceLibraryStore = defineStore('resource-library', {
  state() {
    return {
      resource_library_map: {} as Record<string, ResourceLibrary>,
    }
  },
  actions: {
    async get_resource_library(resource_library_id: string, disable_cache = false): Promise<ResourceLibrary> {
      if (!resource_library_id) return Promise.reject('资源库ID不存在。')
      if (this.resource_library_map[resource_library_id]) return this.resource_library_map[resource_library_id]

      if (!disable_cache && this.resource_library_map[resource_library_id]) {
        return this.resource_library_map[resource_library_id]
      }

      const { data } = await getResourceLibraryInfo(resource_library_id).catch(navigate_to_error)
      this.resource_library_map[data.id] = data
      this.resource_library_map[data.resource_library_id] = data

      return data ? data : Promise.reject('资源库不存在')
    },
    async get_resource_library_tags(resource_library_id: string, disable_cache = false): Promise<ResourceLibraryTag[]> {
      const data = await this.get_resource_library(resource_library_id, disable_cache)
      return data ? data.tag_list : Promise.reject('资源库不存在')
    },
  },
})
