import * as qs from 'qs'
import { is_development_environment } from '../common'
import { isWechatBrowser, isXwgBrowser, sendXwgBrowserOpenMiniProgramEvent } from '../common/device.ts'
import { useWechatStore } from '../../store/modules/wechat.ts'
import router from '../../router/index.ts'

export async function getWx(): Promise<any> {
  return new Promise((resolve, reject) => {
    if (typeof wx !== 'undefined') {
      resolve(wx)
    } else {
      reject(new Error('wx is not defined'))
    }
  })
}

export async function openResourcePackageDetail(resource_package_id: string) {
  const page = '/pages/resource/index/index'
  const query = { id: resource_package_id }

  if (isXwgBrowser()) {
    await openXwgBrowserFtWechatMiniProgram(page, query)
    return
  }

  if (isWechatBrowser()) {
    await redirectToOpenFtWechatMiniProgram(page, query)
    return
  }

  await router.push({ name: 'ResourcePackageIndex', params: { id: resource_package_id } })
}

export async function openFtWechatMiniProgram(page: string, query: any = {}) {
  if (isXwgBrowser()) {
    await openXwgBrowserFtWechatMiniProgram(page, query)
    return
  }

  if (isWechatBrowser()) {
    await redirectToOpenFtWechatMiniProgram(page, query)
    return
  }

  const query_object: any = {
    appid: 'wxde7fb5aafe17a9eb',
    name: 'CC闪传',
    page,
  }
  if (typeof query === 'string') {
    query_object.query = query
  } else if (typeof query === 'object') {
    query_object.query = qs.stringify(query)
  }
  if (is_development_environment()) {
    query_object.version = 'trial'
  }

  await router.push({ name: 'OpenWechatMiniProgram', query: query_object })
}

export async function openXwgBrowserFtWechatMiniProgram(page: string, query: any = {}) {
  let query_string = ''
  if (typeof query === 'string') {
    query_string = query
  } else if (typeof query === 'object') {
    query_string = qs.stringify(query)
  }
  await sendXwgBrowserOpenMiniProgramEvent({
    appid: 'wxde7fb5aafe17a9eb',
    username: 'gh_2efbc77c45f4',
    path: `${page}${query_string.length > 0 ? '?' : ''}${query_string}`,
    type: is_development_environment() ? 2 : 0,
  })
}

export async function redirectToOpenFtWechatMiniProgram(page: string, query: any = {}) {
  const host = is_development_environment() ? 'i3.xwg.cc' : 'i.xwg.cc'
  let query_string = ''
  if (typeof query === 'string') {
    query_string = query
  } else if (typeof query === 'object') {
    query_string = qs.stringify(query)
  }

  const url = `https://${host}/ft/open?${qs.stringify({
    page: page,
    query: query_string,
  })}`

  window.open(url, '_blank')
}

export function updateWechatShare(
  title:
    | string
    | Partial<{
        title: string
        desc: string
        link: string
        imgUrl: string
        success: () => void
      }>,
  desc: string = ''
) {
  const link = window.location.href
  const imgUrl = window.location.origin + '/images/logo.png'
  getWx().then((wx: any) => {
    useWechatStore()
      .onReady(function () {
        const config =
          typeof title === 'object'
            ? title
            : {
                title,
                desc,
                link,
                imgUrl,
                success: function () {
                  console.log('config wechat share success', config)
                },
              }
        if (!config.title) config.title = 'CC闪传资源广场'
        if (!config.imgUrl) config.imgUrl = imgUrl
        if (!config.link) config.link = link
        wx.updateAppMessageShareData(config)
        wx.updateTimelineShareData(config)
      })
      .then()
      .catch((error: any) => {
        console.error('config wechat share error', error)
      })
  })
}
